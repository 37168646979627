import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IReqReceiving, IReqTrack } from "../../types/types";
import { parseError } from "../../utils/utils";
import { mAxios } from "../../utils/api";
import { ITrackReqDto } from "../../types/dto";
import { notification } from "antd";

interface IReqState {
    isLoading: boolean,
    isSendReq: boolean,
    reqTracks: Array<IReqTrack>,
    reqFunds: Array<IReqReceiving>
}

const initialState: IReqState = {
    isLoading: false,
    isSendReq: false,
    reqTracks: [],
    reqFunds: []
}

export const getMyReqTracks = createAsyncThunk(
    'get my req tracks',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IReqTrack>>('track-request/getMyReq');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
);

export const getMyReqsFunds = createAsyncThunk(
    'get my req funds',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IReqReceiving>>('receiving-funds/my');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
)

export const postNewTrackReq = createAsyncThunk(
    'post new track req',
    async (dto: ITrackReqDto) => {
        try {
            await mAxios.post('track-request/create', dto);
            window.location.reload();
        } catch (error) {
            notification.error({ message: (parseError(error)), duration: 3 });
        }
    }
);


const reqTracksSlice = createSlice({
    name: 'req tracks slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyReqTracks.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getMyReqTracks.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.reqTracks = action.payload : state.reqTracks;
            }),
            builder.addCase(getMyReqTracks.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(postNewTrackReq.pending, (state) => {
                state.isSendReq = true;
            }),
            builder.addCase(postNewTrackReq.fulfilled, (state) => {
                state.isSendReq = false;
            }),
            builder.addCase(postNewTrackReq.rejected, (state) => {
                state.isSendReq = false;
            }),


            builder.addCase(getMyReqsFunds.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getMyReqsFunds.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.reqFunds = action.payload : state.reqFunds;
            }),
            builder.addCase(getMyReqsFunds.rejected, (state) => {
                state.isLoading = false;
            })
    }
});


export default reqTracksSlice.reducer;