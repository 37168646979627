import {ChangeEvent, FC, useState, useEffect} from 'react';
import styles from './AddChannelModal.module.scss';
import { AddChannelDto } from '../../../types/dto';
import { IoClose } from "react-icons/io5";
import { Button, Input, theme } from 'antd';
import { EWhiteTheme } from '../../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { addNewChannel } from '../../../redux/slices/channels';
import { getAllTheme } from '../../../redux/slices/whiteTheme';


interface IAddNewChannelModalProps {
    onClose: () => void
}

export const AddChannelModal: FC<IAddNewChannelModalProps> = ({onClose}) => {
    
    const [values, setValues] = useState<AddChannelDto>({
        channelUrl: '',
        themeId: 0
    });

    const {themes} = useAppSelector((state) => state.whiteTheme);

    const {isUploading} = useAppSelector((state) => state.channels);

    const dispatch = useAppDispatch();    

    const updateValues = (e: ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });
    const updateTheme = (themeId: number) => setValues({...values, themeId: themeId});


    useEffect(() => {
        dispatch(getAllTheme());
    },[]);

    console.log(values);

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                <p>Добавить новый канал</p>
                <div className={styles.close_icon_container} onClick={onClose}>
                    <IoClose size={25} color='black' className={styles.close_icon} />
                </div>

                <div className={styles.track_container}>
                    <div className={styles.theme_container}>
                        {
                            themes?.map((item) => {
                                return <div key={item.id} className={values.themeId == item.id ? `${styles.theme} ${styles.on}`: styles.theme} onClick={() => updateTheme(item.id)}>{item.name}</div>
                            })
                        }
                    </div>
                </div>

                <div className={styles.input_container}>
                    <p>Ссылка на канал:</p>
                    <Input placeholder='Ссылка на канал' name='channelUrl' value={values.channelUrl} onChange={(e) => updateValues(e)} />
                    <p className={styles.example}>https://youtube.com/channel/твой_канал</p>
                </div>
                <Button type='primary' loading={isUploading} onClick={() => dispatch(addNewChannel(values))}>Добавить</Button>
            </div>
        </div>
    </>

}