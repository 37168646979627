import { FC, useState } from 'react';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import styles from './TestPage.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'antd';
import { useAppDispatch } from '../../hooks/hooks';
import { ICreatePostDto } from '../../types/dto';



const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false], font: [], size: [] }],
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['link', 'video']
    ]
}

export const TestPage: FC = () => {

    const [values, setValues] = useState<ICreatePostDto>({
        preview: 'https://i.imgur.com/e2Zhvb9.jpeg',
        title: '',
        body: ''
    });
    
    const dispatch = useAppDispatch();

    const updateBody = (text: string) => setValues({...values, 'body': text});
    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => setValues({...values, [e.target.name]: e.target.value});


    return <>
        <MainLayout>
            <div className={styles.container}>
                <div className={styles.test_container}>
                    <input placeholder='Ссылка на фото заголовка imgur' name='preview' onChange={(e) => updateValues(e)} value={values.preview}/>
                    <input placeholder='Заголовок' name='title' onChange={(e) => updateValues(e)}/>

                    <ReactQuill
                        theme='snow'
                        value={values.body}
                        onChange={(e) => updateBody(e)}
                        className='editor-input'
                        modules={modules} />
                    
                    
                    {/* <button onClick={() => dispatch(postTestCreate(values))}>Отправить</button> */}
                </div>
                {/* <div className={styles.test_container} dangerouslySetInnerHTML={{__html: values.body}}>
                </div> */}
                <p className={styles.test_value}>{values.body}</p>
            </div>
        </MainLayout>
    </>

}