import { FC, useState } from 'react';
import stlyes from './PaymentsPage.module.scss';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { AddPaymentMethodModal } from '../../components/modalsComponents/AddPaymentMethodModal/AddPaymentMethodModal';
import { MyInvoicesComponent } from '../../components/MyInvoicesComponent/MyInvoicesComponent';
import { MyTransactionsComponent } from '../../components/transactionComponents/MyTransactionsComponent/MyTransactionsComponent';


export const PaymentsPage: FC = () => {

    const [addInvoiceModal, setAddInvoiceModal] = useState<boolean>(false);

    return <>
        <MainLayout>
            <div className={stlyes.container}>
                <div className={stlyes.header}>
                    <h1>Финансы</h1>

                    <div className={stlyes.btns_container}>
                        <button className={stlyes.btn} onClick={() => setAddInvoiceModal(true)}>Добавить реквизиты</button>
                    </div>
                </div>

                <div className={stlyes.finance_container}>
                    <MyTransactionsComponent/>
                    <MyInvoicesComponent/>
                </div>
            </div>
        </MainLayout>
        {
            addInvoiceModal && <AddPaymentMethodModal onClose={() => setAddInvoiceModal(false)} />
        }
    </>

}