import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Main } from './Pages/Main/Main';
import { Login } from './Pages/Login/Login';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { useEffect } from 'react';
import { getMe } from './redux/slices/user';
import { WhiteContent } from './Pages/WhiteContent/WhiteContent';
import { StaticsPage } from './Pages/Statics/Statics';
import { ReqPage } from './Pages/ReqTracks/ReqTracksPage';
import { PaymentsPage } from './Pages/Payments/PaymentsPage';
import { BlogPage } from './Pages/Blog/Blog';
import { TestPage } from './Pages/TestPage/TestPage';
import { BlogItemPage } from './Pages/BlogItemPage/BlogItemPage';
import { AuthRouter } from './utils/AuthRouter';
import { updatePaymentMethodStatus } from './redux/slices/navigation';
import { VerifyUserPage } from './Pages/VerifyUserPage/VerifyUserPage';
import { ReferralPage } from './Pages/RefferalPage/ReferralPage';

function App() {

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token != null) {
      dispatch(getMe());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      if (localStorage.getItem('verify') == null || 'false') {
        if (user.contactInfo != null) {
          localStorage.setItem('verify', 'true');
        } else {
          localStorage.setItem('verify', 'false');
        }
      }
      if (user.paymentMethod?.length == 0) {
        dispatch(updatePaymentMethodStatus(true));
      }
    }
  }, [user]);


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route element={<AuthRouter />}>
            <Route path='/verify' element={<VerifyUserPage />} />
            <Route path='/' element={<Main />} />
            <Route path='/white' element={<WhiteContent />} />
            <Route path='/req/:name' element={<ReqPage />} />
            <Route path='/statistics' element={<StaticsPage />} />
            <Route path='/payments' element={<PaymentsPage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/blog/:id' element={<BlogItemPage />} />
            <Route path='/test' element={<TestPage />} />
            <Route path='/referral' element={<ReferralPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
