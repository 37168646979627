import { MainLayout } from '../../components/MainLayout/MainLayout';
import { WhiteChannelsComponent } from '../../components/WhiteChannelsComponent/WhiteChannelsComponent';
import styles from './WhiteContent.module.scss';
import {FC} from 'react';


export const WhiteContent: FC = () => {

    return <>
        <MainLayout>
            <WhiteChannelsComponent/>
        </MainLayout>
    </>

}