import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { notification } from "antd";
import { parseError } from "../../utils/utils";
import { mAxios } from "../../utils/api";
import { IRefSystem } from "../../types/types";

interface IReferralsState {
    referrals: Array<IRefSystem>,
    isLoading: boolean
}

const initialState: IReferralsState = { 
    referrals: [],
    isLoading: false
}

export const getMyReferrals = createAsyncThunk(
    'get my referrals',
    async () => {
        try {
            const {data} = await mAxios.get<Array<IRefSystem>>('referrals/myReferrals');   
            return data;
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
);

const referralsSlice = createSlice({
    name: 'referrals slice',
    initialState, 
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyReferrals.pending, (state) => {
            state.isLoading = true;
        }), 
        builder.addCase(getMyReferrals.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.referrals = action.payload : state.referrals = [];

        }), 
        builder.addCase(getMyReferrals.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export default referralsSlice.reducer;