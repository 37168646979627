import styles from './AddRedFlagModal.module.scss';
import { FC } from 'react';
import { BsShieldExclamation } from "react-icons/bs";
import { MdAudiotrack } from "react-icons/md";
import { IChain, ITrack } from '../../../types/types';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { postRedFlag } from '../../../redux/slices/chains';


interface AddRedFlagModal {
    onClose: () => void,
    chain: IChain;
}


export const AddRedFlagModal: FC<AddRedFlagModal> = ({ onClose, chain }) => {

    const {track} = chain;
    const {isPostRedFlag} = useAppSelector((state) => state.chains);
    const dispatch = useAppDispatch();

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                {/* <p>Добавить "красную метку"</p> */}
                <BsShieldExclamation className={styles.icon} />

                <div className={styles.desc_container}>
                    <p>Перед тем как отправить заявление, что этот трек не работает, проверьте на нём авторское право на своих видео или что-то в таком духе, что то подобное нужно написать, так?</p>
                </div>

                <div className={styles.track_info}>
                    <MdAudiotrack size={25}/>

                    <div>
                        <p>{track.originalName}</p>
                        <p>ID: {track.id}</p>
                    </div>
                </div>

                <div className={styles.flex_container}>
                    <p>Я проверил авторское право: </p>
                    <Button loading={isPostRedFlag} onClick={() => dispatch(postRedFlag(chain.id))}>Отправить</Button>
                </div>
            </div>
        </div>
    </>

}