import { FC, useState } from 'react';
import styles from './ReqTrackModal.module.scss';
import { PiWarningCircleDuotone } from "react-icons/pi";
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import { EReasons } from '../../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { postNewTrackReq } from '../../../redux/slices/req';


interface IReqTrackModalProps {
    onClose: () => void
}


export const ReqTrackModal: FC<IReqTrackModalProps> = ({ onClose }) => {

    const [reason, setReason] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const dispatch = useAppDispatch();
    const { isSendReq } = useAppSelector((state) => state.reqs);


    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                <p>Заявка на выдачу трека</p>

                <div className={styles.info_container}>
                    <PiWarningCircleDuotone className={styles.icon} />
                    <p>При подаче заявки, администратору будет отправлен запрос, о вашем желании получить личный трек.<br />Необходимо дождаться отклонения или одобрения вашей заявки.<br /><span>Спасибо за внимание!</span></p>
                </div>

                <div className={styles.reasons_container}>
                    {
                        Object.values(EReasons).map((item) => {
                            return <div className={`${styles.item} ${reason == item ? styles.on : ''}`} onClick={() => setReason(item)}>{item}</div>
                        })
                    }
                </div>

                <div className={styles.input_container}>
                    <p className={styles.desc_text}><span>*</span>Опишите почему вы желаете получить трек(необязательно).</p>
                    <TextArea className={styles.text_area} placeholder='Опишите причину (необязательно)!' allowClear maxLength={500} showCount onChange={(e) => setDescription(e.target.value)} />
                </div>

                <Button className={styles.btn} loading={isSendReq} onClick={() => dispatch(postNewTrackReq({reason, description}))}>Отправить</Button>
            </div>
        </div>
    </>

}
