import { FC } from 'react';
import styles from './TransactionReferralItem.module.scss';
import { PiUsersThree } from "react-icons/pi";
import { ITransaction } from '../../../types/types';
import { convertDateAndTime } from '../../../utils/utils';


interface ITransactionItemProps {
    transaction: ITransaction
}

export const TransactionReferralItem: FC<ITransactionItemProps> = ({transaction}) => {

    return <>
        <div className={styles.container}>
            <div className={styles.left_container}>
                <PiUsersThree className={`${styles.icon} ${styles.receiving}`} />
                <div>
                    <p className={styles.title}>Реферальная система</p>
                    <p className={styles.desc}>реферал: {transaction.referrer?.referral.nick}</p>
                </div>
            </div>

            <div className={styles.right_container}>
                <p className={styles.amount}>+{transaction.amount}₽</p>
                <p className={styles.date}>{convertDateAndTime(transaction?.create_at)}</p>
            </div>
        </div>
    </>

}