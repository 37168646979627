import styles from './Header.module.scss';
import { FC, useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import { BiUser } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { useAppSelector } from '../../hooks/hooks';
import { PiWallet } from "react-icons/pi";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { GoHome } from "react-icons/go";
import { AiOutlineYoutube, AiOutlineLineChart, AiOutlineDollar, AiOutlineUsergroupAdd } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { CgClose } from "react-icons/cg";
import { UpdateUserDataModal } from '../modalsComponents/updateUserDataModal/UpdateUserDataModal';
import { WithdrawModal } from '../modalsComponents/WithdrawModal/WithdrawModal';


export const Header: FC = () => {

    const { user } = useAppSelector((state) => state.user);

    const [openUser, setOpenUser] = useState(false);
    const [openWithdraw, setOpenWithdraw] = useState(false);
    const [openNavigation, setOpenNavigation] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('verify') == 'false') {
            navigate('/verify');
        }
    }, [user]);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('verify');
        window.location.reload();
    }

    return <>
        <div className={styles.header}>

            <div className={openNavigation ? styles.open_nav : styles.close_nav}>
                <div className={styles.top}>
                    <p>Silver bro</p>
                </div>

                <CgClose className={styles.close_icon} onClick={() => setOpenNavigation(false)} />

                <div className={styles.navigations}>
                    <div className={styles.item}>
                        <ul>
                            <li className={`${styles.item} ${styles.blog}`} onClick={() => navigate('/blog')}>
                                <AiOutlineUsergroupAdd size={25} />
                                <p>Блог</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/')}>
                                <GoHome size={25} />
                                <p>Серый контен</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/req/tracks')}>
                                <AiOutlineYoutube size={25} />
                                <p>Заявки</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/white')}>
                                <AiOutlineYoutube size={25} />
                                <p>Белый контент</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/statistics')}>
                                <AiOutlineLineChart size={25} />
                                <p>Статистика</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/payments')}>
                                <AiOutlineDollar size={25} />
                                <p>Финансы</p>
                            </li>
                            <li className={styles.item} onClick={() => navigate('/referral')}>
                                <AiOutlineUsergroupAdd size={25} />
                                <p>Рефералы</p>
                            </li>

                            <div className={styles.other_container}>
                                <li className={`${styles.item} ${styles.close}`} onClick={() => logout()}>
                                    <AiOutlineLogout size={25} />
                                    <p>Выйти</p>
                                </li>
                                <li className={`${styles.item} ${styles.settings}`} onClick={() => {
                                    setOpenUser(true)
                                    setOpenNavigation(false)
                                }}>
                                    <IoSettingsOutline size={25} />
                                    <p>Настройки</p>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={styles.container}>

                <div className={styles.left_container}>
                    <HiOutlineMenuAlt1 className={styles.menu_icon} onClick={() => setOpenNavigation(true)} />

                    <div className={styles.balance_container}>
                        <PiWallet size={25} />
                        <div>
                            <div className={styles.title}>Баланс</div>
                            <div className={styles.balance}>{user?.balance}₽</div>
                        </div>
                        {
                            user.balance > 0 && <button className={styles.withdraw_btn} onClick={() => setOpenWithdraw(!openWithdraw)}>Вывод</button>
                        }
                    </div>
                </div>

                {/* <div className={styles.search_input}>
                    <IoIosSearch size={25} />
                    <input placeholder='Поиск' />
                </div> */}

                <div className={styles.user_events_container}>
                    <div className={styles.btn_container}>
                        <IoSettingsOutline size={25} className={styles.btn} onClick={() => setOpenUser(!openUser)} />
                        <AiOutlineLogout size={25} className={styles.btn} onClick={() => logout()} />
                    </div>

                    <div className={styles.info_user_container}>

                        <div className={styles.user_container}>
                            <div className={styles.info_container}>
                                <BiUser size={25} />
                                <div>
                                    <p className={styles.nick}>{user?.nick}</p>
                                    <p className={styles.desc}>ID: {user?.id}</p>
                                </div>
                            </div>
                            {/* <div onClick={() => setOpenUser(!openUser)}>
                                <IoIosArrowDown size={25} className={openUser ? styles.arrow_open : styles.arrow_close} />
                            </div> */}
                        </div>

                        {/* <div className={`${styles.user_data_container} ${openUser ? styles.open : ''}`}>
                            <ul>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                            </ul>
                        </div> */}

                    </div>
                </div>

            </div>
        </div >
        {
            openUser && <UpdateUserDataModal onClose={() => setOpenUser(false)} />
        }
        {
            openWithdraw && <WithdrawModal onClose={() => setOpenWithdraw(!openWithdraw)}/>
        }
    </>
}