import { FC } from 'react';
import styles from './ReqItemFunds.module.scss';
import { AiOutlineFieldTime, AiOutlineCheckCircle } from "react-icons/ai";
import { IReqReceiving } from '../../types/types';
import { convertDateAndTime } from '../../utils/utils';


interface IReqItemFundsProps {
    item: IReqReceiving
}


export const ReqItemFunds: FC<IReqItemFundsProps> = ({ item }) => {

    return <>
        <div className={styles.container}>
            {
                item?.status == 'в ожидании' ?  <AiOutlineFieldTime className={styles.icon} /> : item?.status == 'выполнено' && <AiOutlineCheckCircle className={styles.icon}/>
            }

            <div className={styles.info_container}>
                <div className={styles.flex_container}>
                    <p className={styles.title}><span>Выплата</span> #{item?.id}</p>
                    <p className={styles.time}>{convertDateAndTime(item?.create_at)}</p>
                </div>
                <p className={styles.desc}>{item.contentType == 'white' ? `Id канала: ${item?.whiteContent?.channelId}` : `трек: ${item?.grayContent?.track?.originalName}`}</p>
            </div>
            <div className={`${styles.status} ${item?.status == 'выполнено' ? `${styles.complete}` : item.status == 'отклонено' && `${styles.denided}`}`}>{item?.status}</div>
        </div>
    </>

}