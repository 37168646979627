import moment from "moment";
import 'moment/locale/ru';
import 'moment-timezone';
import { IChannel } from "../types/types";

export const parseError = (e: any) => {
    const messages = JSON.parse(e.request.response).message;
    if (Array.isArray(messages)) {
        return messages.join(' ');
    }
    return messages;
}

export enum EWhiteTheme {
    deep_house = 'deep house',
    car_music = 'car music',
    phonk_music = 'phonk music',
    lo_fi = 'lo-fi'
}

export enum EReasons { 
    test_1 = 'Слетели Авторские права на трек',
    test_2 = 'Нужен для нового каналa',
    test_3 = 'Другое',
}

export const convertDate = (date: string) => {
    moment.locale('ru');
    return moment(date).format('Do MMMM YYYY');
}

export const convertDateAndTime = (date: string) => {
    moment.locale('ru');
    return moment(date).format('MMMM Do YYYY, HH:mm');
}

export enum ETransactionType {
    withdraw = 'вывод',
    receiving = 'получение',
    referral_fee = 'реферал'
}


export enum EPaymentMethod {
    tinkoff = 'TINKOFF',
    qiwi = 'QIWI',
    usdt= 'USDT TRC-20'
}


export const getProfitChannel = (channel: IChannel): number => {
    return channel.transactions.reduce((a,b) => a + b.amount, 0);
}