import styles from './TransactionWithdrawItem.module.scss';
import { FC } from 'react';
import { ITransaction } from '../../../types/types';
import { ETransactionType, convertDateAndTime } from '../../../utils/utils';
import { BiMoneyWithdraw, BiTimeFive } from "react-icons/bi";


interface ITransactionItemProps {
    transaction: ITransaction
}

export const TransactionWithdrawItem: FC<ITransactionItemProps> = ({ transaction }) => {

    return <>
        <div className={styles.container}>
            <div className={styles.left_container}>
                {
                    transaction.status == 'в ожидании' ? <BiTimeFive className={`${styles.icon} ${styles.receiving}`} /> :
                        <BiMoneyWithdraw className={`${styles.icon} ${styles.receiving}`} />
                }
                <div>
                    <p className={styles.title}>Вывод</p>
                    <p className={styles.desc}>Реквизиты: {transaction.paymentMethod?.invoice}</p>
                </div>
            </div>

            <div className={styles.right_container}>
                <p className={styles.amount}>-{transaction.amount}₽</p>
                <p className={styles.date}>{convertDateAndTime(transaction?.create_at)}</p>
            </div>
        </div>
    </>

}