import { FC } from 'react';
import styles from './ReferralItem.module.scss';
import { AiOutlineUser } from "react-icons/ai";
import { IRefSystem } from '../../types/types';
import { convertDateAndTime } from '../../utils/utils';

interface IReferralItemProps {
    referral: IRefSystem
}

export const ReferralItem: FC<IReferralItemProps> = ({ referral }) => {

    return <>
        <div className={styles.ref_container}>
            <div style={{display: 'flex', gap: '10px'}}>
                <AiOutlineUser className={styles.user_icon} />

                <div className={styles.user_info_container}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '25px', marginBottom: '5px' }}>
                        <p className={styles.nick}>{referral?.referral?.nick}</p>
                        <p className={styles.time}>{convertDateAndTime(referral?.referral?.created_at)}</p>
                    </div>

                    <div className={styles.data_container}>
                        <div>
                            <p className={styles.other}>Тг: <span>{referral?.referral?.contactInfo?.telegram ?? 'отсутствует'}</span></p>
                            <p className={styles.other}>Рефералы: <span>{referral?.referral?.referrals?.length}</span></p>
                        </div>
                        <div className={styles.divider} />
                        <div>
                            <p className={styles.other}>Кол-во каналов: <span>{referral?.referral?.whiteChannels?.length}</span></p>
                            <p className={styles.other}>Кол-во треков: <span>{referral?.referral?.chains?.length}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.profit_container}>{referral.totalProfit}₽</div>
        </div>
    </>

}