import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ITrack } from '../../types/types';
import { mAxios } from '../../utils/api';
import { parseError } from '../../utils/utils';

interface ITracksState {
    tracks: Array<ITrack>;
    isLoading: boolean;
}

const initialState: ITracksState = {
    tracks: [],
    isLoading: false
}

// export const getMyTracks = createAsyncThunk(
//     'get my tracks',
//     async () => {
//         try {
//             const {data} = await mAxios.get<Array<ITrack>>('track/my');
//             return data;
//         } catch (error) {
//             alert(parseError(error));
//         }
//     }
// );

export const trackSlice = createSlice({
    name: 'tracks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // builder.addCase(getMyTracks.pending, (state) => {
        //     state.isLoading = true;
        //     state.tracks = [];
        // }),
        // builder.addCase(getMyTracks.fulfilled, (state, action) => {
        //     state.isLoading = true;
        //     action.payload != undefined ? state.tracks = action.payload : state.tracks = [];
        // }),
        // builder.addCase(getMyTracks.rejected, (state) => {
        //     state.isLoading = true;
        // })
    }
});

export default trackSlice.reducer;