import styles from './AddPaymentMethod.module.scss';
import { FC, useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import usdtLogo from '../../../assets/usdt_logo.svg';
import tinkoff_logo from '../../../assets/tinkoff.svg';
import { Button } from 'antd';
import { IoClose } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { AddPaymentMethodCardDto, AddPaymentMethodUsdt } from '../../../types/dto';
import { postNewPaymentMethod, postNewPaymentMethodUsdt } from '../../../redux/slices/payment_method';



interface IAddPaymentMethodProps {
    onClose: () => void
}


export const AddPaymentMethodModal: FC<IAddPaymentMethodProps> = ({ onClose }) => {

    const dispatch = useAppDispatch();
    const { isUploading } = useAppSelector((state) => state.payment_method);

    const [viewListPayment, setViewListPayment] = useState(false);
    const [nameMethod, setNameMethod] = useState<string>('tinkoff');

    const [invoice, setInvoice] = useState<AddPaymentMethodCardDto>({
        invoice: '',
        nameOnCard: ''
    });

    const [invoiceUsdt, setInvoiceUsdt] = useState<AddPaymentMethodUsdt>({
        invoice: ''
    });

    const handleIpnutCrypto = (e: React.ChangeEvent<HTMLInputElement>) => setInvoiceUsdt({ ...invoiceUsdt, [e.target.name]: e.target.value });
    const handleIpnutCard = (e: React.ChangeEvent<HTMLInputElement>) => setInvoice({ ...invoice, [e.target.name]: e.target.value });

    const selectReciveMethod = (method: string) => {
        setNameMethod(method);
        setViewListPayment(!viewListPayment);
    }

    const addNewMethod = () => {
        if(nameMethod == 'tinkoff'){
            dispatch(postNewPaymentMethod(invoice))
        }
        if(nameMethod == 'USDT'){
            dispatch(postNewPaymentMethodUsdt(invoiceUsdt))
        }
    }

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <p className={styles.title}>Добавление реквизитов</p>

                <div className={styles.close_modal_container} onClick={onClose}>
                    <IoClose className={styles.icon} />
                </div>

                <div className={styles.inputs_container}>
                    <p>Способы выплаты</p>

                    <div className={styles.select_method_container}>
                        <p>USDT (TRC-20), Тинькофф</p>
                        <IoIosArrowDown className={`${styles.arrow} ${viewListPayment ? styles.open : styles.close}`} onClick={() => setViewListPayment(!viewListPayment)} />
                    </div>

                    <div className={`${styles.dropdown_list} ${viewListPayment ? styles.open : ''}`}>
                        <div className={styles.item} onClick={() => selectReciveMethod('USDT')}>
                            <img src={usdtLogo} className={styles.payment_icon} />

                            <div className={styles.info_container}>
                                <p className={styles.name}>USDT (TRC-20)</p>
                                <p className={styles.desc}>USDT TRC-20 – это стейблкоин с привязкой к доллару США, выпущенный в сети TRON.</p>
                            </div>
                        </div>

                        <div className={styles.item} onClick={() => selectReciveMethod('tinkoff')}>
                            <img src={tinkoff_logo} className={styles.payment_icon} />

                            <div className={styles.info_container}>
                                <p className={styles.name}>Тинькофф</p>
                                <p className={styles.desc}>«Тинько́фф Банк» — российский коммерческий банк.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    nameMethod == 'tinkoff' && <div className={styles.invoice_container}>
                        <div className={styles.input_container}>
                            <p><span>*</span>Номер карты</p>
                            <input placeholder='0000 0000 0000 0000' name='invoice' onChange={(e) => handleIpnutCard(e)} maxLength={16} />
                        </div>
                        <div className={styles.input_container}>
                            <p><span>*</span>Владелец карты</p>
                            <input placeholder='IVAN IVANOV' name='nameOnCard' onChange={(e) => handleIpnutCard(e)} />
                        </div>

                        <div className={styles.select_container}>
                            <p>Выбран:<span>Тинькофф</span></p>
                            <img src={tinkoff_logo} />
                        </div>
                    </div>
                }

                {
                    nameMethod == 'USDT' && <div className={styles.invoice_container}>
                        <div className={styles.input_container}>
                            <p><span>*</span>Адрес USDT (TRC-20)</p>
                            <input placeholder='TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' name='invoice' onChange={(e) => handleIpnutCrypto(e)} />
                        </div>

                        <div className={styles.select_container}>
                            <p>Выбран:<span>USDT (TRC-20)</span></p>
                            <img src={usdtLogo} />
                        </div>
                    </div>
                }

                <Button className={styles.btn} loading={isUploading} onClick={() => addNewMethod()}>Сохранить</Button>
            </div>
        </div>
    </>

}