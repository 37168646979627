import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface INavigationState {
    position: string,
    isNonePaymentMethod: boolean,
}

const initialState: INavigationState =  {
    position: '/',
    isNonePaymentMethod: false,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState, 
    reducers: {
        updatePosition(state, action: PayloadAction<string>){
            state.position = action.payload;
        },
        updatePaymentMethodStatus(state, action: PayloadAction<boolean>){
            state.isNonePaymentMethod = action.payload;
        }
    }
});

export default navigationSlice.reducer;

export const {updatePosition, updatePaymentMethodStatus} = navigationSlice.actions;