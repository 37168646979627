import { Spin } from 'antd';
import { BlogItem } from '../../components/BlogItem/BlogItem';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getPosts, getPostsBySectionId } from '../../redux/slices/blog';
import { getAllSections } from '../../redux/slices/sections';
import styles from './Blog.module.scss';
import { FC, useEffect } from 'react';
import { antIcon } from '../../components/CustomSpin/CustomSpin';


export const BlogPage: FC = () => {

    const disaptch = useAppDispatch();
    const { isLoading, posts } = useAppSelector((state) => state.blog);
    const { sections, isLoading: isLoadingSections } = useAppSelector((state) => state.sections);

    useEffect(() => {
        disaptch(getAllSections());
        disaptch(getPosts());
    }, []);

    return <>
        <MainLayout>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Блог</h1>
                </div>

                <div className={styles.all_blog_sections}>
                    {
                        sections?.map((section) => {
                            return <div className={styles.item} key={section.id} onClick={() => disaptch(getPostsBySectionId(section.id))}>{section.name}</div>
                        })
                    }
                </div>

                <div className={styles.items}>
                    {isLoading ? <Spin className={'spin'} indicator={antIcon} /> :
                        posts?.map((item) => {
                            return <BlogItem key={item.id} post={item} />
                        })
                    }
                </div>

            </div>
        </MainLayout>
    </>

}