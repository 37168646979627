import { ChangeEvent, FC, useState } from 'react';
import styles from './AddNewVideo.module.scss';
import { IoClose } from "react-icons/io5";
import { Input, Button } from 'antd';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { addLink } from '../../../redux/slices/video';
import { AddVideoLinkDto } from '../../../types/dto';
import { IChain, ITrack } from '../../../types/types';

interface IAddNewVideoModalProps {
    chain: IChain,
    onClose: () => void
}

export const AddNewVideoModal: FC<IAddNewVideoModalProps> = ({chain, onClose}) => {

    const { isUploadingVideo } = useAppSelector((state) => state.videos);

    const dispatch = useAppDispatch();

    const [values, setValues] = useState<AddVideoLinkDto>({
        link: '',
        chainId: chain.id
    });

    const {track} = chain;

    const updateValues = (e: ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
                <p>Добавление ролика к треку</p>
                <div className={styles.close_icon_container} onClick={onClose}>
                    <IoClose size={25} color='black' className={styles.close_icon} />
                </div>

                <div className={styles.track_container}>
                    <p>Трек:</p>
                    <div className={styles.track}>
                        <PiSoundcloudLogoDuotone size={30} />
                        <div>
                            <p className={styles.title}>{track.originalName}</p>
                            <p className={styles.other}>ID: {track.id}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.input_container}>
                    <p>Ссылка на видео:</p>
                    <Input placeholder='Ссылка на видео' name='link' value={values.link} onChange={(e) => updateValues(e)} />
                </div>
                <Button loading={isUploadingVideo} type='primary' onClick={() => dispatch(addLink(values))}>Добавить</Button>
            </div>
        </div>
    </>

}