import { FC, useEffect, useState } from 'react';
import styles from './WhiteChannelsComponent.module.scss';
import { IoAddOutline } from "react-icons/io5";
import { ChannelItem } from '../ChannelItem/ChannelItem';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getMyChannels, getMyChannelsByTheme } from '../../redux/slices/channels';
import { AddChannelModal } from '../modalsComponents/addChannelModal/AddChannelModal';
import { getAllTheme } from '../../redux/slices/whiteTheme';
import { CustomSpin, antIcon } from '../CustomSpin/CustomSpin';
import { Spin } from 'antd';


export const WhiteChannelsComponent: FC = () => {

    const [isOpenAddChannel, setIsOpenAddChannel] = useState<boolean>(false);


    const dispatch = useAppDispatch();

    const {channels, isLoading} = useAppSelector((state) => state.channels);
    const {themes} = useAppSelector((state) => state.whiteTheme);


    useEffect(() => {
        dispatch(getMyChannels());
        dispatch(getAllTheme());
    }, []);

    if(isLoading){
        return <Spin indicator={antIcon} className={'spin'}/>
    }

    return <>
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.count}>Мои каналы: <span>{channels?.length}</span></h1>

                <div className={styles.right_container}>
                    {/* <div className={styles.search_track}>
                        <AiOutlineSearch size={30} className={styles.icon} />
                        <input placeholder='Найти канал' />
                    </div> */}

                    <div className={styles.themes_contianer}>
                        <div className={styles.item} onClick={() => dispatch(getMyChannels())}>все</div>
                        {
                            themes?.map((theme) => {
                                return <div className={styles.item} key={theme.id} onClick={() => dispatch(getMyChannelsByTheme(theme.id))}>{theme.name}</div>
                            })
                        }
                    </div>

                    <button className={styles.add_btn} onClick={() => setIsOpenAddChannel(true)}>
                        <IoAddOutline size={23} color='black' /> Добавить канал
                    </button>
                </div>
            </div>

            <div className={styles.items_container}>
                {
                    channels?.map((channel) => {
                        return <ChannelItem channel={channel} key={channel.id}/>
                    })
                }
            </div>
        </div>
        {
            isOpenAddChannel && <AddChannelModal onClose={() => setIsOpenAddChannel(false)}/>
        }
    </>

}