import { FC, useEffect } from 'react';
import styles from './MyTransactionsComponent.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import transactions, { getMyTransactions } from '../../../redux/slices/transactions';
import { TransactionReceivingItem } from '../TransactionItem/TransactionItem';
import { ETransactionType } from '../../../utils/utils';
import { TransactionReferralItem } from '../TransactionReferralItem/TransactionReferralItem';
import { Spin } from 'antd';
import { antIcon } from '../../CustomSpin/CustomSpin';
import { TransactionWithdrawItem } from '../TransactionWithdrawItem/TransactionWithdrawItem';


export const MyTransactionsComponent: FC = () => {

    const dispatch = useAppDispatch();
    const { transactions, isLoading } = useAppSelector((state) => state.transactions);

    useEffect(() => {
        dispatch(getMyTransactions());
    }, []);

    return <>
        <div className={styles.container}>
            {/* <p>Транзакция пока нету!</p> */}
            {
                isLoading ? <Spin indicator={antIcon} className={'spin'} /> :
                    transactions?.map((transaction) => {
                        if (transaction.type == ETransactionType.receiving) {
                            return <TransactionReceivingItem transaction={transaction} key={transaction.id} />
                        }
                        if (transaction.type == ETransactionType.referral_fee) {
                            return <TransactionReferralItem transaction={transaction} key={transaction.id} />
                        }
                        if (transaction.type == ETransactionType.withdraw) {
                            return <TransactionWithdrawItem transaction={transaction} key={transaction.id} />
                        }
                    })
            }
        </div>
    </>

}