import { notification } from 'antd';
import styles from './CountViewComponent.module.scss';
import { FC, useState } from 'react';
import { AiOutlineQuestion } from "react-icons/ai";


interface ICountViewComponent {
    title: string;
    count: string;
    descText: string;
    color: string
}

export const CountViewComponent: FC<ICountViewComponent> = ({ title, count, descText, color }) => {

    return <>
        <div className={styles.container}>
            <AiOutlineQuestion className={styles.quest_btn} onClick={() => notification.info({message: descText, duration: 10})} />

            <div className={styles.header}>
                <p>{title}</p>
            </div>

            <p className={styles.view_count}>{count}</p>
            <div className={styles.test} style={{background: color, filter: `drop-shadow(0 0 10px ${color})`}}/>
        </div>
    </>

}