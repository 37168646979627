import { FC, useEffect } from 'react';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import styles from './ReferralPage.module.scss';
import { ReferralItem } from '../../components/ReferralItem/ReferralItem';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getMyReferrals } from '../../redux/slices/referrals';
import { Spin } from 'antd';
import { antIcon } from '../../components/CustomSpin/CustomSpin';


export const ReferralPage: FC = () => {

    const dispatch = useAppDispatch();
    const { referrals, isLoading } = useAppSelector((state) => state.referrals);
    const {user} = useAppSelector((state) => state.user);

    useEffect(() => {
        dispatch(getMyReferrals());
    }, []);

    return <>
        <MainLayout>
            <div className={styles.container}>
                <div>
                    <h1>Мои рефералы: <span>{referrals?.length}</span></h1>


                    <div className={styles.referral_container}>
                        {
                            isLoading ? <Spin className='spin' indicator={antIcon} /> :
                                referrals?.map((referral) => {
                                    return <ReferralItem referral={referral} key={referral.id} />
                                })
                        }
                    </div>
                </div>

                <div className={styles.referral_info_container}>
                    <div className={styles.referrer_container}>
                        <h2>Реферальная система</h2>

                        <div className={styles.info}>
                            <h4>Вы получаете <span>10%</span> от дохода реферальной системы.</h4>
                            <p>Ваша реферальный код: <span className={styles.referralCode}>{user.referralCode}</span></p>
                        </div>
                    </div>
                
                </div>
            </div>
        </MainLayout>
    </>

}