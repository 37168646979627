import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getMyPaymentsMethod } from '../../redux/slices/payment_method';
import { EPaymentMethod } from '../../utils/utils';
import { OrangeCardItem } from '../OrangeCardItem/OrangeCardItem';
import { UsdtCardItem } from '../UsdtCardItem/UsdtCardItem';
import styles from './MyInvoicesComponent.module.scss';
import { FC, useEffect } from 'react';


export const MyInvoicesComponent: FC = () => {

    const { isLoading, invoices } = useAppSelector((state) => state.payment_method);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getMyPaymentsMethod());
    }, []);

    return <>
        <div className={styles.container}>

            <div className={styles.items_container}>
                {
                    invoices?.map((item) => {
                        if (item.nameMethod == EPaymentMethod.tinkoff) {
                            return <OrangeCardItem invoice={item} key={item.id} />
                        }
                        if(item.nameMethod == EPaymentMethod.usdt){
                            return <UsdtCardItem invoice={item} key={item.id}/>
                        }
                    })
                }
            </div>
        </div>
    </>

}