import { FC } from 'react';
import styles from './Mian.module.scss';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { TracksComponent } from '../../components/tracksComponents/TracksComponent/TracksComponent';



export const Main: FC = () => {

    return <>
        <MainLayout>
            <div className={styles.container}>
                <div className={styles.sections_container}>
                    <div className={styles.user_data_container}>
                        <TracksComponent />
                        {/* <VideosComponent /> */}
                    </div>
                </div>
            </div>
        </MainLayout>
    </>
}