import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IChannel, IStatisticsContent } from "../../types/types";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { AddChannelDto } from "../../types/dto";
import { notification } from "antd";

interface IChannelsState {
    channels: Array<IChannel>,
    isLoading: boolean,
    isUploading: boolean,
    whiteStatistics: IStatisticsContent,
    isLoadingStatistics: boolean
}

const initialState: IChannelsState = {
    channels: [],
    isLoading: false,
    isUploading: false,
    whiteStatistics: {} as IStatisticsContent,
    isLoadingStatistics: false
}


export const getMyChannels = createAsyncThunk(
    'get my channels',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IChannel>>('white-content/myChannels');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
);


export const addNewChannel = createAsyncThunk(
    'add new channel',
    async (dto: AddChannelDto) => {
        try {
            await mAxios.post(`white-content/addWhiteChannel/${dto.themeId}`, dto);
            window.location.reload();
        } catch (error) {
            notification.error({message: parseError(error)});
        }
    }
);


export const getStatisticsMyChannels = createAsyncThunk(
    'get statistics my channels',
    async () => {
        try {
            const {data} = await mAxios.get<IStatisticsContent>('white-content/getStaticsMyChannels');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
);

export const getMyChannelsByTheme = createAsyncThunk(
    'get my channels by theme',
    async (themeId: number) => {
        try {
            const {data} = await mAxios.get<Array<IChannel>>(`white-content/myChannelsByThemeId/${themeId}`);
            return data;
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
)

export const postUpdateStaticsMyChannels = createAsyncThunk(
    'post update statics my channles',
    async () => {
        try {
            await mAxios.post('/white-content/updateStaticsMyChannels');
            window.location.reload();
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
)


const channelsSlice = createSlice({
    name: 'channels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyChannels.pending, (state) => {
            state.isLoading = true;
            state.channels = [];
        }),
        builder.addCase(getMyChannels.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.channels = action.payload : state.channels = [];
        }),
        builder.addCase(getMyChannels.rejected, (state) => {
            state.channels = [];
            state.isLoading = false;
        }),



        builder.addCase(getMyChannelsByTheme.pending, (state) => {
            state.isLoading = true;
            state.channels = [];
        }),
        builder.addCase(getMyChannelsByTheme.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.channels = action.payload : state.channels = [];
        }),
        builder.addCase(getMyChannelsByTheme.rejected, (state) => {
            state.channels = [];
            state.isLoading = false;
        }),



        builder.addCase(addNewChannel.pending, (state) => {
            state.isUploading = true;
        }),
        builder.addCase(addNewChannel.fulfilled, (state) => {
            state.isUploading = false;
        }),builder.addCase(addNewChannel.rejected, (state) => {
            state.isUploading = false;
        }),


        builder.addCase(getStatisticsMyChannels.pending, (state) => {
            state.isLoadingStatistics = true;
        }),
        builder.addCase(getStatisticsMyChannels.fulfilled, (state, action) => {
            state.isLoadingStatistics = false;
            action.payload != undefined ? state.whiteStatistics = action.payload : state.whiteStatistics;
        }),
        builder.addCase(getStatisticsMyChannels.rejected, (state) => {
            state.isLoadingStatistics = false;
        })
    }
});


export default channelsSlice.reducer;