import { FC, useState, useEffect } from 'react';
import styles from './UpdateUserDataModal.module.scss';
import { Button } from 'antd';
import { ChangeUserDataDto } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { changeUserData } from '../../../redux/slices/user';
import { CgClose } from "react-icons/cg";



interface IUpdateUserModalProps {
    onClose: () => void
}

export const UpdateUserDataModal: FC<IUpdateUserModalProps> = ({onClose}) => {

    const {user, isLoading} = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const [values, setValues] = useState<ChangeUserDataDto>({
        nick: user?.nick,
        telegram: user?.contactInfo?.telegram,
        phone: user?.contactInfo?.phone,
        email: user?.contactInfo?.email
    });

    useEffect(() => {
        setValues({nick: user?.nick, telegram: user?.contactInfo?.telegram, phone: user?.contactInfo?.phone, email: user?.contactInfo?.email})
    },[user]);

    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => setValues({...values, [e.target.name]: e.target.value});

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <CgClose size={25} className={styles.close} onClick={onClose}/>

                <p className={styles.title}>Смена данных</p>

                <div className={styles.inputs_container}>
                    <div className={styles.item}>
                        <p>Телеграм</p>
                        <input placeholder='@telegram' value={values?.telegram ?? ''} name='telegram' onChange={(e) => updateValues(e)}/>
                    </div>
                
                    <div className={styles.item}>
                        <p>Почта</p>
                        <input placeholder='mail@mail.ru' value={values?.email ?? ''} name='email' onChange={(e) => updateValues(e)}/>
                    </div>

                    <div className={styles.item}>
                        <p>Ник</p>
                        <input placeholder='nick' value={values?.nick ?? ''} name='nick' onChange={(e) => updateValues(e)}/>
                    </div>

                    <div className={styles.item}>
                        <p>Телефон</p>
                        <input placeholder='7 912 ... ...' value={values?.phone ?? ''} name='phone'  onChange={(e) => updateValues(e)}/>
                    </div>
                </div>
            
                <Button loading={isLoading} onClick={() => dispatch(changeUserData(values))}>Сохранить</Button>
            </div>
        </div>
    </>

}