import {FC} from 'react';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { ReqsComponent } from '../../components/ReqsComponent/ReqsComponent';


export const ReqPage: FC = () => {

    return <>
        <MainLayout>
            <ReqsComponent/>
        </MainLayout>
    </>

}