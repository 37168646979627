import { AddPaymentMethod } from '../modalsComponents/AddPaymentMethod/AddPaymentMethod';
import { Header } from '../Header/Header';
import { NavLeft } from '../NavLeft/NavLeft';
import styles from './MainLayout.module.scss';
import {FC, PropsWithChildren, useEffect} from 'react';
import { useAppSelector } from '../../hooks/hooks';

export const MainLayout: FC<PropsWithChildren<unknown>> = ({children}) => {

    const {isNonePaymentMethod} = useAppSelector((state) => state.navigation);
    const {user} = useAppSelector((state) => state.user);

    return <>
        <div className={styles.main_container}>
            <NavLeft/>
            <div className={styles.container}>
                <Header/>
                {isNonePaymentMethod && <AddPaymentMethod />}
                {children}
            </div>
        </div>
    </>   

}