import styles from './Login.module.scss';
import { FC, useState, ChangeEvent, useEffect } from 'react';
import { AiOutlineLogin, AiOutlineLock } from "react-icons/ai";
import { mAxios } from '../../utils/api';
import { parseError } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';


export const Login: FC = () => {

    const navigate = useNavigate();

    const [isLoading, setisLoading] = useState(false);

    const [values, setValues] = useState({
        nick: '',
        password: ''
    });

    const valuesHandler = (e: ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate('/');
        }
    },[]);

    const submitForm = async() => {
        setisLoading(true);
        try {
            const {data} = await mAxios.post('auth/login', values);
            //console.log(data);
            localStorage.setItem('token', data.token);
            setisLoading(false);
            window.location.reload();
        } catch (error) {
            setisLoading(false);
            notification.error({message: parseError(error), duration: 3});
        }
    }

    return <>
        <div className={styles.container}>
            <div className={styles.form}>
                <h1>SILVER BRO</h1>

                <div className={styles.inputs_container}>
                    <div className={styles.input_container}>
                        <AiOutlineLogin size={20} />
                        <input placeholder='Логин' maxLength={50} name='nick' onChange={(e) => valuesHandler(e)}/>
                    </div>
                    <div className={styles.input_container}>
                        <AiOutlineLock size={20} />
                        <input placeholder='Пароль' maxLength={50} name='password' onChange={(e) => valuesHandler(e)} type='password'/>
                    </div>
                </div>

                <Button loading={isLoading} onClick={() => submitForm()}>Войти</Button>

            </div>
        </div>
    </>

}