import {FC} from 'react';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import styles from './Statics.module.scss';
import { WhiteStaticsComponent } from '../../components/WhiteStaticsComponent/WhiteStaticsComponent';
import { GrayStaticsComponent } from '../../components/GrayStaticsComponent/GrayStaticsComponent';

export const StaticsPage: FC = () => {

    return <>
        <MainLayout>
            <div className={styles.container}>
                <WhiteStaticsComponent/>
                <div className={styles.border}/>
                <GrayStaticsComponent/>
            </div>
        </MainLayout>
    </>

}