import { FC } from 'react';
import styles from './ReqTrackItem.module.scss';
import { AiOutlineFieldTime } from "react-icons/ai";
import { IReqTrack } from '../../../types/types';
import { convertDateAndTime } from '../../../utils/utils';


interface IReqTrackItemProps {
    reqTrack: IReqTrack
}


export const ReqTrackItem: FC<IReqTrackItemProps> = ({reqTrack}) => {

    return <>
        <div className={styles.container}>
            <AiOutlineFieldTime className={styles.icon} />

            <div className={styles.info_container}>
                <div className={styles.flex_container}>
                    <p className={styles.title}>{reqTrack?.reason}</p>
                    <p className={styles.time}>{convertDateAndTime(reqTrack?.create_at)}</p>
                </div>
                <p className={styles.desc}>{reqTrack?.description}</p>
            </div>

            <div className={styles.status}>{reqTrack?.status}</div>
        </div>
    </>

}