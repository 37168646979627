import {FC} from 'react';
import styles from './AddPaymentMethod.module.scss';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';


export const AddPaymentMethod: FC = () => {

    const navigate = useNavigate();

    return <>
        <div className={styles.container}>
            <AiOutlineInfoCircle className={styles.icon}/>
            <div>Вы не можете получать выплаты, так как у вас не добавлены платежные реквизиты. <span onClick={() => navigate('/payments')}>Добавить сейчас!</span></div>
        </div>
    </>

}