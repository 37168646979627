import styles from './TracksComponent.module.scss';
import { FC, useEffect, useState } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import { IoAddOutline } from "react-icons/io5";
import { TrackItem } from '../TrackItem/TrackItem';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getMyChains } from '../../../redux/slices/chains';
import { ReqTrackModal } from '../../modalsComponents/ReqTrackModal/ReqTrackModal';
import { Spin } from 'antd';
import { antIcon } from '../../CustomSpin/CustomSpin';


export const TracksComponent: FC = () => {

    const dispatch = useAppDispatch();
    const { chains, isLoading } = useAppSelector((state) => state.chains);

    const [isOpenReq, setIsOpenReq] = useState(false);
    //const [isOpenRed, setIsOpenRed] = useState(false);

    useEffect(() => {
        dispatch(getMyChains());
    }, []);

    return <>
        <div className={styles.track_info}>

            <div className={styles.header}>
                <h1 className={styles.track_count}>Серый контент: <span>{chains.length}</span></h1>

                <div className={styles.right_container}>
                    <div className={styles.search_track}>
                        <AiOutlineSearch size={30} className={styles.icon} />
                        <input placeholder='Найти трек' />
                    </div>

                    <button className={styles.add_track_btn} onClick={() => setIsOpenReq(true)}>
                        <IoAddOutline /> Запросить трек
                    </button>
                </div>

            </div>


            <div className={styles.tracks_container}>
                {
                    isLoading ? <Spin className='spin' indicator={antIcon} /> :
                        chains?.map((chain) => {
                            return <TrackItem chain={chain} key={chain.id} />
                        })
                }
            </div>
        </div>

        {
            isOpenReq && <ReqTrackModal onClose={() => setIsOpenReq(false)} />
        }
    </>

}