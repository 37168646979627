import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ReqsComponent.module.scss';
import { ReqTrackItem } from '../tracksComponents/ReqTrackItem/ReqTrackItem';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getMyReqTracks, getMyReqsFunds } from '../../redux/slices/req';
import { useNavigate, useParams } from 'react-router-dom';
import { ReqItemFunds } from '../ReqItemFunds/ReqItemFunds';

export const ReqsComponent: FC = () => {

    const { name } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isLoading, reqTracks, reqFunds} = useAppSelector((state) => state.reqs);

    useMemo(() => {
        if(name == 'tracks'){
            dispatch(getMyReqTracks());
        }

        if(name == 'content'){
            dispatch(getMyReqsFunds());
        }
    }, [name]);
    

    return <>
        <div className={styles.container}>

            <div className={styles.header}>
                {
                    name == 'tracks' ? <h1>Заявки по трекам: <span>{reqTracks?.length}</span></h1> :
                    name == 'content' && <h1>Заявки по выплате: <span>{reqFunds?.length}</span></h1>
                }
                <div className={styles.btns_container}>
                    <button onClick={() => navigate('/req/tracks')}>треки</button>
                    <button onClick={() => navigate('/req/content')}>выплаты</button>
                </div>
            </div>

            <div className={styles.items_container}>
                {
                    name == 'tracks' && reqTracks.length > 0 && reqTracks?.map((item) => {
                        return <ReqTrackItem reqTrack={item} key={item.id} />
                    })
                }
                {
                    name == 'content' && reqFunds.length > 0 && reqFunds?.map((item) => {
                        return <ReqItemFunds item={item} key={item.id}/>
                    })
                }
            </div>

        </div>
    </>

}