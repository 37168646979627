import { FC, useEffect } from 'react';
import styles from './WhiteStaticsComponent.module.scss';
import { CountViewComponent } from '../StaticsComponents/CountViewComponent/CountViewComponent';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ChannelItem } from '../ChannelItem/ChannelItem';
import { getMyChannels, getStatisticsMyChannels, postUpdateStaticsMyChannels } from '../../redux/slices/channels';
import { GrUpdate } from "react-icons/gr";
import { Spin } from 'antd';
import { antIcon } from '../CustomSpin/CustomSpin';


export const WhiteStaticsComponent: FC = () => {

    const dispatch = useAppDispatch();
    const { channels, isLoading, whiteStatistics, isLoadingStatistics } = useAppSelector((state) => state.channels);

    useEffect(() => {
        dispatch(getMyChannels());
        dispatch(getStatisticsMyChannels());
    }, []);

    return <>
        <div className={styles.container}>
            <div className={styles.container_header}>
                <h1>Белый контент</h1>

                <div onClick={() => dispatch(postUpdateStaticsMyChannels())}>
                    <GrUpdate size={25} className={styles.update} />
                </div>
            </div>

            <div className={styles.statics_container}>
                {
                    isLoadingStatistics ? <Spin indicator={antIcon} className={'spin'}/> : <>
                        <CountViewComponent title='Просмотры' count={`${whiteStatistics.viewCount}`} descText='Просмотры - общее количество просмотров за все ваши видео.' color='#fe4a49'/>
                        <CountViewComponent title='Коммерческие просмотры' count={`${whiteStatistics.commercialViews}`} descText='Коммерческие просмотры — это просмотры, которые сопровождаются рекламой. Именно за них авторы на YouTube получают деньги с просмотров — монетизацию видео.' color=' #fed766'/>
                        <CountViewComponent title='Доход' count={`${whiteStatistics.profit}₽`} descText='Додох с коммерческих просмотров после получения отчётов.' color='#7bc043'/>
                    </>
                }
            </div>

            <div className={styles.items_container}>
                <div className={styles.header}>
                    <h2>Каналы</h2>
                    <p className={styles.count}>{channels?.length}</p>
                </div>

                <div className={styles.items}>
                    {
                        channels?.map((channel) => {
                            return <ChannelItem channel={channel} key={channel.id} />
                        })
                    }
                </div>
            </div>
        </div>
    </>

}