import { configureStore } from "@reduxjs/toolkit";
import user from './slices/user';
import tracks from './slices/tracks';
import videos from './slices/video';
import channels from './slices/channels';
import chains from './slices/chains';
import reqs from "./slices/req";
import payment_method from "./slices/payment_method";
import blog from './slices/blog';
import sections from "./slices/sections";
import whiteTheme from "./slices/whiteTheme";
import transactions from "./slices/transactions";
import navigation from "./slices/navigation";
import referrals from "./slices/referrals";


export const store = configureStore({
    reducer: {
        user,
        tracks,
        videos,
        channels,
        chains,
        reqs,
        payment_method,
        blog,
        sections,
        whiteTheme,
        transactions,
        navigation,
        referrals
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;