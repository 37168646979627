import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IWhiteTheme } from "../../types/types";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";

interface IWhiteThemeState {
    themes: Array<IWhiteTheme>,
    isLoading: boolean
}

const initialState: IWhiteThemeState = {
    themes: [],
    isLoading: false
}

export const getAllTheme = createAsyncThunk(
    'get all theme',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IWhiteTheme>>('/white-theme/all');
            return data;
        } catch (error) {
            alert(parseError(error));
        }
    }
);

const whiteThemeSlice = createSlice({
    name: 'white theme slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllTheme.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getAllTheme.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.themes = action.payload : state.themes;
            }),
            builder.addCase(getAllTheme.rejected, (state) => {
                state.isLoading = false;
            })
    }
});


export default whiteThemeSlice.reducer;