import {FC} from 'react';
import styles from './WrongDataChannelItem.module.scss';
import { PiSmileySad } from "react-icons/pi";
import { Button } from 'antd';


export const WrongDataChannelItem: FC = () => {

    return <>
        <div className={styles.container}>
            <PiSmileySad className={styles.icon}/>
            
            <div className={styles.info}>
                <p className={styles.title}>Не удалось получить информацию!</p>
                <p className={styles.desc}>Обычно, это происходит из-за некорректной ссылки на ваш канал, попробуйте обновить её!</p>

                <button>Обновить</button>
            </div>
        </div>
    </>

}