import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getMyAllViews, getMyChains } from '../../redux/slices/chains';
import { ContentTrackItem } from '../ContentTrackItem/ContentTrackItem';
import { CountViewComponent } from '../StaticsComponents/CountViewComponent/CountViewComponent';
import styles from './GrayStaticsComponent.module.scss';
import {FC, useEffect} from 'react';
import { antIcon } from '../CustomSpin/CustomSpin';


export const GrayStaticsComponent: FC = () => {

    const dispatch = useAppDispatch();
    const {chains, viewsCount, isLoading} = useAppSelector((item) => item.chains);

    useEffect(() => {
        dispatch(getMyChains());
        dispatch(getMyAllViews());
    },[]);

    return <>
        <div className={styles.container}>
            <h1>Серый контент</h1>

            <div className={styles.statics_container}>
                <CountViewComponent title='Просмотры' count={viewsCount?.viewCount?.toString() ?? 0} descText='Просмотры - общее количество просмотров за все ваши видео.'  color='#f37736'/>
                <CountViewComponent title='Коммерческие просмотры' count={viewsCount?.commercialViews?.toString() ?? 0} descText='Коммерческие просмотры — это просмотры, которые сопровождаются рекламой. Именно за них авторы на YouTube получают деньги с просмотров — монетизацию видео.' color=' #fdf498'/>
                <CountViewComponent title='Доход' count={viewsCount?.profit?.toString() ?? 0} descText='Додох с коммерческих просмотров после получения отчётов.' color='#7bc043'/>
            </div>

            <div className={styles.items_container}>
                <div className={styles.header}>
                    <h2>Треки</h2>
                    <p className={styles.count}>{chains?.length}</p>
                </div>

                <div className={styles.items}>
                    {isLoading ? <Spin className='spin' indicator={antIcon}/> :
                        chains?.map((chain) => {
                            return <ContentTrackItem chain={chain} key={chain.id}/>
                        })
                    }
                    {/* <TracksTableComponents/> */}
                </div>
            </div>
        </div>
    </>

}