import { FC, useEffect } from 'react';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { useParams } from 'react-router-dom';
import styles from './BlogItemPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getPostById } from '../../redux/slices/blog';
import { Spin } from 'antd';
import { antIcon } from '../../components/CustomSpin/CustomSpin';


export const BlogItemPage: FC = () => {

    const { id } = useParams();

    const dispatch = useAppDispatch();
    const { currentPost, isLoading } = useAppSelector((state) => state.blog);

    if (id != undefined) {
        useEffect(() => {
            dispatch(getPostById(+id));
        }, []);
    }



    return <>
        <MainLayout>
            {
                isLoading && <Spin className='spin' indicator={antIcon}/>
            }
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>{currentPost?.title}</h1>
                    <img src={currentPost?.preview} />
                </div>
                <div className={styles.text_body} dangerouslySetInnerHTML={{__html: currentPost?.body}}/>
            </div>
        </MainLayout>
    </>

}