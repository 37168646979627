import { FC, useState, useEffect } from 'react';
import styles from './VerifyUserPage.module.scss';
import { Button, notification } from 'antd';
import { parseError } from '../../utils/utils';
import { mAxios } from '../../utils/api';
import { ISaveContactInfoDto } from '../../types/dto';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router-dom';


export const VerifyUserPage: FC = () => {

    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.user);

    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState<ISaveContactInfoDto>({
        email: '',
        phone: null,
        telegram: ''
    });

    useEffect(() => {
        if (localStorage.getItem('verify') == 'true') {
            navigate('/');
        }
    }, []);

    const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    const postSaveContactInfo = async () => {
        setIsLoading(true);
        try {
            await mAxios.post('contact-info/save', {
                ...values,
                phone: values.phone?.length == 0 ? null : values.phone
            });

            setIsLoading(false);
            localStorage.setItem('verify', 'true');
            window.location.reload();
        } catch (error) {
            setIsLoading(false);
            notification.error({ message: parseError(error), duration: 3 });
        }
    }

    return <>
        <div className={styles.container}>

            <div className={styles.header}>
                <img src="https://img.icons8.com/emoji/60/null/waving-hand-emoji.png" />

                <div className={styles.text_container}>
                    <h1>Привет, бро!</h1>
                    <h2>Добро пожаловать в <span>SILVER BRO</span> коммьюнити!</h2>
                    <p>Для дальнейшей работы с нами вам необходимо заполнить информацию ниже.</p>
                </div>
            </div>

            <div className={styles.info_container}>
                <div className={styles.data_container}>
                    <p className={styles.desc}>Введите данные</p>

                    <div className={styles.inputs_container}>
                        <div className={styles.item}>
                            <p className={styles.title}><span>*</span>Телеграм</p>
                            <input placeholder='@tvoy_telegram' name='telegram' onChange={(e) => updateValues(e)} />
                        </div>
                        <div className={styles.item}>
                            <p className={styles.title}><span>*</span>email</p>
                            <input placeholder='tvoy_email@mail.com' name='email' onChange={(e) => updateValues(e)} />
                        </div>
                        <div className={styles.item}>
                            <p className={styles.title_phone}>телефон<span> (необязательно)</span></p>
                            <input placeholder='7912000 ...' name='phone' type='tel' onChange={(e) => updateValues(e)} />
                        </div>
                    </div>

                    <Button loading={isLoading} onClick={() => postSaveContactInfo()}>Сохранить</Button>
                </div>
            </div>
        </div>

    </>
}