import { Button, notification } from 'antd';
import styles from './WithdrawModal.module.scss';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getMyPaymentsMethod } from '../../../redux/slices/payment_method';
import { EPaymentMethod, parseError } from '../../../utils/utils';
import usdtLogo from '../../../assets/usdt_logo.svg';
import tinkoff_logo from '../../../assets/tinkoff.svg';
import { CgClose } from "react-icons/cg";
import { mAxios } from '../../../utils/api';


interface Props {
    onClose: () => void
}

export const WithdrawModal: FC<Props> = ({ onClose }) => {

    const [amount, setAmount] = useState<number>(0);
    const [invoiceId, setInvoiceId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { invoices } = useAppSelector((state) => state.payment_method);

    useEffect(() => {
        dispatch(getMyPaymentsMethod());
    }, []);

    const withdrawMoney = async () => {
        setLoading(true);
        try {
            await mAxios.post('transactions/createWithdraw', { invoiceId, amount });
            setLoading(false);
            window.location.reload();
        } catch (error) {
            notification.error({ message: parseError(error), duration: 3 });
            setLoading(false);
        }
    }

    return <>
        <div className={styles.container}>
            <div className={styles.modal}>
                <CgClose size={25} className={styles.close} onClick={onClose} />

                <p className={styles.title}>Вывод средств</p>

                <div className={styles.withdraw_input_container}>
                    <p>Вывести: </p>
                    <input placeholder='Сумма для вывода' type='number' onChange={(e) => setAmount(+e.target.value)} />
                </div>

                {
                    invoices.map((item) => {
                        if (item.nameMethod == EPaymentMethod.tinkoff) {
                            return <div className={`${styles.method} ${invoiceId == item.id && styles.selected}`} onClick={() => setInvoiceId(item.id)}>
                                <img src={tinkoff_logo} />

                                <div>
                                    <p>{item.nameMethod}</p>
                                    <p className={styles.invoice}>{item.invoice}</p>
                                </div>

                            </div>
                        }
                        if (item.nameMethod == EPaymentMethod.usdt) {
                            return <div className={`${styles.method} ${invoiceId == item.id && styles.selected}`} onClick={() => setInvoiceId(item.id)}>
                                <img src={usdtLogo} />

                                <div>
                                    <p>{item.nameMethod}</p>
                                    <p className={styles.invoice}>{item.invoice}</p>
                                </div>

                            </div>
                        }
                    })
                }

                <Button loading={loading} onClick={() => withdrawMoney()}>Вывести {amount}₽</Button>
            </div>
        </div>
    </>

}