import styles from './NavLeft.module.scss';
import { FC } from 'react';
import { GoHome, GoPaperAirplane, GoPlay } from "react-icons/go";
import { AiOutlineYoutube, AiOutlineLineChart, AiOutlineDollar, AiOutlineUsergroupAdd } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { updatePosition } from '../../redux/slices/navigation';
import { BsPostcard } from "react-icons/bs";


export const NavLeft: FC = () => {

    const navigate = useNavigate();

    const { position } = useAppSelector((state) => state.navigation);
    const dispatch = useAppDispatch();

    return <>
        <div className={styles.container}>
            <div className={styles.logo} />

            <ul className={styles.items_container}>
                <li className={`${styles.item} ${position == '/blog' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/blog'));
                    navigate('/blog');
                }}>
                    <BsPostcard size={25} />
                    <p>Блог</p>
                </li>

                <li className={`${styles.item} ${position == '/' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/'));
                    navigate('/')
                }}>
                    <GoPlay size={25} />
                    <p>Серый контент</p>
                </li>

                <li className={`${styles.item} ${position == '/req/tracks' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/req/tracks'));
                    navigate('/req/tracks');
                }}>
                    <GoPaperAirplane size={25} />
                    <p>Заявки</p>
                </li>

                <li className={`${styles.item} ${position == '/white' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/white'));
                    navigate('/white');
                }}>
                    <AiOutlineYoutube size={25} />
                    <p>Белый контент</p>
                </li>

                <li className={`${styles.item} ${position == '/statistics' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/statistics'));
                    navigate('/statistics');
                }}>
                    <AiOutlineLineChart size={25} />
                    <p>Статистика</p>
                </li>

                <li className={`${styles.item} ${position == '/payments' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/payments'));
                    navigate('/payments')
                }}>
                    <AiOutlineDollar size={25} />
                    <p>Финансы</p>
                </li>

                <li className={`${styles.item} ${position == '/referral' ? styles.active : ''}`} onClick={() => {
                    dispatch(updatePosition('/referral'));
                    navigate('/referral')
                }}>
                    <AiOutlineUsergroupAdd size={25} />
                    <p>Рефералы</p>
                </li>
            </ul>
        </div>
    </>

}