import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { ITrack, IVideo } from '../../../types/types';
import { VideoItem } from '../../VideoItem/VideoItem';
import styles from './ViewVideoByTrackModal.module.scss';
import { FC } from 'react';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

interface ViewVideoByTrackModalProps {
    track: ITrack,
    videos: Array<IVideo>,
    onClose?: () => void
}

export const ViewVideoByTrackModal: FC<ViewVideoByTrackModalProps> = ({ videos, onClose, track }) => {

    //const { isOpenViewVideosByTrack } = useAppSelector((state) => state.modals);
    const dispatch = useAppDispatch();

    return <>
        <div className={styles.container} onClick={onClose}>
            <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>

                <div className={styles.track}>
                    <PiSoundcloudLogoDuotone size={30} />
                    <div>
                        <p className={styles.title}>{track.originalName}</p>
                        <p className={styles.other}>ID: {track.id}</p>
                    </div>

                    <div className={styles.btn_close} onClick={onClose}>
                        <IoClose size={25}/>
                    </div>
                </div>

                <div className={styles.count_video}>{videos.length} видео</div>

                {
                    videos.map((video) => {
                        return <VideoItem video={video} key={video.id}/>
                    })
                }

            </div>
        </div>
    </>

}