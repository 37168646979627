import {FC} from 'react';
import styles from './TransactionItem.module.scss';
import { MdOutlineAttachMoney } from "react-icons/md";
import { ITransaction } from '../../../types/types';
import { convertDateAndTime } from '../../../utils/utils';


interface ITransactionItemProps {
    transaction: ITransaction
}


export const TransactionReceivingItem: FC<ITransactionItemProps> = ({transaction}) => {

    return <>
        <div className={styles.container}>
            <div className={styles.left_container}>
                <MdOutlineAttachMoney className={`${styles.icon} ${styles.receiving}`}/>
                <div>
                    <p className={styles.title}>Выплата</p>
                    <p className={styles.desc}>id заявки: {transaction?.receivingReq?.id}</p>
                </div>
            </div>
            
            <div className={styles.right_container}>
                <p className={styles.amount}>+{transaction.amount}₽</p>
                <p className={styles.date}>{convertDateAndTime(transaction?.create_at)}</p>
            </div>
        </div>
    </>

}