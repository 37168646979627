import { FC, useState, useEffect, MouseEvent } from 'react';
import styles from './ChannelItem.module.scss';
import { AiOutlineDelete } from "react-icons/ai";
import { IChannel } from '../../types/types';
import { convertDate, getProfitChannel, parseError } from '../../utils/utils';
import { apiGetChannelInform, mAxios } from '../../utils/api';
import { IYouTubeChannel } from '../../types/youtube_channel';
import { WrongDataChannelItem } from '../WrongDataChannelItem/WrongDataChannelItem';
import { Button, Spin, notification } from 'antd';
import { MdOutlineAttachMoney } from "react-icons/md";
import { CustomSpin, antIcon } from '../CustomSpin/CustomSpin';
import logo from '../../assets/usdt_logo.svg';



interface WhiteChannelProps {
    channel: IChannel
}


export const ChannelItem: FC<WhiteChannelProps> = ({ channel }) => {

    const [data, setData] = useState<IYouTubeChannel>();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPostPayment, setIsPostPayment] = useState(false);

    const getChannelInfo = async () => {
        try {
            const { data } = await apiGetChannelInform(channel.channelId);

            setData(data);
        } catch (error) {
            alert(parseError(error));
        }
    }

    const deleteMyChannel = async (channelId: number) => {
        setIsDeleting(true);
        try {
            await mAxios.delete(`white-content/deleteMyChannel/${channelId}`);
            setIsDeleting(false);
            window.location.reload();
        } catch (error) {
            setIsDeleting(false);
            alert(parseError(error));
        }
    }


    const getPaymentForMyChannel = async () => {
        setIsPostPayment(true);
        try {
            await mAxios.post(`receiving-funds/createWhite/${channel.id}`);
            setIsPostPayment(false);
            notification.success({message: 'Заявка была успешно отправлена!', duration: 2});
        } catch (error) {
            setIsPostPayment(false);
            notification.error({message: parseError(error), duration: 3});
        }
    }


    useEffect(() => {
        getChannelInfo();
    }, []);

    if (data == undefined) {
        return <CustomSpin />
    }

    if (data.items == undefined) {
        return <WrongDataChannelItem />
    }

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        window.open(`https://www.youtube.com/channel/${channel.channelId}`);
    }   

    return <>
        <div className={styles.container}>
            <div className={styles.left}>
                <img className={styles.img} src={data?.items[0].snippet.thumbnails.default.url} onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = 'https://i.imgur.com/HXHLARp.jpeg';
                }} alt={logo}/>

                <div className={styles.data}>
                    <div className={styles.header}>
                        <div>
                            <a className={styles.title} onClick={(e) => handleClick(e)}>{data?.items[0].snippet.title}</a>
                            <p className={styles.tag}>{data?.items[0].snippet.customUrl}</p>
                        </div>

                        <p className={styles.date}>{convertDate(data?.items[0].snippet.publishedAt)}</p>
                    </div>

                    <div className={styles.other_container}>
                        <div className={styles.static_container}>
                            <p>Просмотры: <span>{data?.items[0].statistics.viewCount}</span></p>
                            <p>Подписчики: <span>{data?.items[0].statistics.subscriberCount}</span></p>
                            <p>Видео: <span>{data?.items[0].statistics.videoCount}</span></p>
                            <p>Доход: <span>{getProfitChannel(channel)}₽</span></p>
                        </div>
                    </div>
                </div>

                <div className={styles.delete_container} onClick={() => deleteMyChannel(channel.id)}>
                    <AiOutlineDelete className={styles.delete_icon} />
                </div>
            </div>

            <div className={styles.flex_container}>
                <div className={styles.flex_container}>
                    {isDeleting && <Spin className={styles.spin} indicator={antIcon} />}
                    <Button className={styles.get_paymen_btn} loading={isPostPayment} icon={<MdOutlineAttachMoney size={15} />} onClick={() => getPaymentForMyChannel()}>запросить выплату</Button>
                </div>
                <div className={styles.theme}>{channel.theme.name}</div>
            </div>
        </div>
    </>

}