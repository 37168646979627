import { ChangeUserDataDto, IInvoice, IUser } from "../../types/types"
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import axios, { AxiosError } from "axios";
import { notification } from "antd";

interface IUserState {
    user: IUser;
    isLoading: boolean
}

const initialState: IUserState = {
    user: {} as IUser,
    isLoading: false
}

export const changeUserData = createAsyncThunk(
    'update user data',
    async (dto: ChangeUserDataDto) => {
        try {
            await mAxios.post('users/updateData', dto);
        } catch (error) {
            notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const getMe = createAsyncThunk(
    'GET ME',
    async () => {
        try {
            const { data } = await mAxios.get<IUser>('users/me');
            // const {data} = await axios.get('http://localhost:5000/users/me', {withCredentials: true});
            //console.log('');
            return data as IUser;
        } catch (error: any) {
            if (error.response.status) {
                localStorage.removeItem('token');
                window.location.reload();
            }
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getMe.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload as IUser;
            }),
            builder.addCase(getMe.rejected, (state) => {
                state.isLoading = false;
            }),
    
            builder.addCase(changeUserData.pending, (state) => {
                state.isLoading = true;
            }),
                builder.addCase(changeUserData.fulfilled, (state) => {
                    state.isLoading = false;
                    //state.user = action.payload as IUser;
                }),
                builder.addCase(changeUserData.rejected, (state) => {
                    state.isLoading = false;
                })
        }
});

export default userSlice.reducer;